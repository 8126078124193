import React from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/system';

// Custom Divider with gradient
const GradientDivider = styled('div')(({ theme }) => ({
  height: '2px',
  background: 'linear-gradient(to right, #FF6A00, black)',
  border: 'none',
  margin: theme.spacing(2, 0),
}));

const FAQSection = () => {
  const faqs = [
    {
      question: 'O que é o Chat GPT-4?',
      answer:
        'Chat GPT-4 é uma avançada inteligência artificial desenvolvida para gerar respostas naturais e precisas em conversas, além de auxiliar na criação de conteúdos e na automação de tarefas com linguagem natural.',
    },
    {
      question: 'Como posso acessar o Chat GPT-4?',
      answer:
        'Para acessar o Chat GPT-4, basta realizar a assinatura no nosso site. Após a compra, você receberá um e-mail com todas as instruções para ativar seu acesso instantâneo e começar a usar imediatamente.',
    },
   
    
    {
      question: 'Qual é a política de reembolso?',
      answer:
        'Oferecemos uma garantia de 7 dias. Se você não estiver satisfeito com o Chat GPT-4, pode solicitar um reembolso completo sem complicações dentro desse período.',
    },
    {
      question: 'Quais recursos estão incluídos no Chat GPT-4?',
      answer:
        'O Chat GPT-4 oferece uma ampla gama de recursos, como geração de textos, respostas em múltiplos idiomas, assistência em estudos, automação de tarefas de escrita e muito mais. Tudo otimizado para aumentar sua produtividade.',
    },
    {
      question: 'Preciso de conhecimento técnico para usar o Chat GPT-4?',
      answer:
        'Não! O Chat GPT-4 foi projetado para ser acessível a todos, desde iniciantes até usuários avançados. Sua interface intuitiva facilita o uso, sem a necessidade de experiência prévia com IA ou programação.',
    },
  ];

  return (
    <Box
      sx={{
        maxWidth: '1200px',
        margin: 'auto',
        padding: '40px 20px',
        backgroundColor: '#000', // Fundo preto
        color: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" align="center" gutterBottom>
        Perguntas Frequentes
      </Typography>
      <GradientDivider />

      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          sx={{
            backgroundColor: '#111', // Fundo escuro para cada item do Accordion
            border: '1px solid #FF6A00', // Borda laranja
            marginBottom: '10px',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: '#FF6A00' }} />} // Ícone expandir laranja
            aria-controls={`faq-content-${index}`}
            id={`faq-header-${index}`}
          >
            <Typography sx={{ color: 'white', fontWeight: 'bold' }}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ color: 'white' }}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
