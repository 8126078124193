import React from 'react';
import { Box, Typography, Link } from '@mui/material';
import { styled } from '@mui/system';

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#000', // Fundo preto
  color: 'white',
  padding: theme.spacing(2),
  textAlign: 'center',
}));

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant="body2">
        &copy; {new Date().getFullYear()} Segue no instagram @mestretiktok
      </Typography>
      
    </FooterContainer>
  );
};

export default Footer;
