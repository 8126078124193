import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export default function VideoSection() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black', // Fundo preto para destacar o vídeo
        color: 'white', // Definindo cor de texto branca
        textAlign: 'center',
      }}
    >
     
      <Box
        sx={{
          border: '5px solid #FF6A00', // Moldura laranja para chamar atenção
          borderRadius: '10px',
          overflow: 'hidden',
          width: { xs: '100%', sm: '80%', md: '60%', lg: '50%' }, // Tornar responsivo
          mb: 4,
        }}
      >
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/-gYjn5JcbUI" // Novo vídeo incorporado
          title="Novo Vídeo YouTube"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>

      {/* Botão de Chamada para Ação */}
      <Button
        variant="contained"
        size="large"
        sx={{
          padding: '12px 40px',
          borderRadius: '30px',
          fontWeight: 'bold',
          fontSize: { xs: '14px', md: '18px' },
          backgroundColor: '#FF6A00',
          '&:hover': {
            backgroundColor: '#FF5000', // Cor de hover para destacar
          },
        }}
        href="https://pay.kiwify.com.br/8NjkSoU" // Link que leva à oferta
      >
        Obtenha Acesso Imediato ao Chat GPT 4!    
      </Button>
    </Box>
  );
}
