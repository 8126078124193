import React from 'react';
import { Box, Typography, Button } from '@mui/material';

export default function Header() {
  const scrollToContactForm = () => {
    document.getElementById('contact-form').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Box sx={{ textAlign: 'center', paddingBottom: '50px', backgroundColor: 'black', padding: '60px 20px' }}>
      {/* Nome do Produto */}
      <Typography 
        variant="h5" 
        sx={{ color: '#FF6A00', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}
      >
        Chat GPT-4
      </Typography>

      {/* Headline chamativa */}
      <Typography 
        variant="h3" 
        sx={{ 
          fontFamily: 'Montserrat, sans-serif', 
          fontWeight: 'bold', 
          color: 'white',
          mb: 2
        }}
      >
        Revolucione Suas Conversas e Tarefas com a Inteligência Avançada do Chat GPT-4!
      </Typography>

      {/* Subheadline */}
      <Typography 
        variant="h5" 
        sx={{ 
          fontFamily: 'Montserrat, sans-serif', 
          color: '#FF6A00',
          fontWeight: '500',
          mb: 4
        }}
      >
        Automação inteligente e comunicação eficaz. Acelere a resolução de problemas e impulsione sua produtividade.
      </Typography>

      {/* Botão de ação 
      <Button 
        variant="outlined" 
        onClick={scrollToContactForm}
        sx={{ 
          color: '#FF6A00', 
          borderColor: '#FF6A00', 
          marginTop: '30px', 
          fontFamily: 'Montserrat, sans-serif', 
          fontWeight: 'bold', 
          '&:hover': {
            borderColor: '#FF5000',
            color: '#FF5000',
          }
        }}
      >
        Experimente Agora
      </Button>
      */}
    </Box>
  );
}
