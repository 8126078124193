import React from 'react';
import { Typography, Box, Paper, Button } from '@mui/material';

const LastDoubtsSection = () => {
  return (
    <Box sx={{ maxWidth: '1200px', margin: 'auto', textAlign: 'center', padding: '40px 20px' }}>
      {/* Título da seção */}
      <Typography variant="h4" align="center" sx={{ mb: 2, fontFamily: 'Roboto, sans-serif', color: '#FF6A00', fontWeight: 'bold' }}>
        TIRE SUAS ÚLTIMAS DÚVIDAS
      </Typography>
      
      <Typography variant="h5" align="center" sx={{ mb: 4, fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 'normal' }}>
        Assista ao vídeo abaixo para esclarecer suas dúvidas sobre o Chat gpt 4 !
      </Typography>
      
      {/* Papel para o vídeo */}
      <Paper sx={{ padding: '20px', backgroundColor: '#1a1a1a', borderRadius: '15px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.7)' }}>
        <iframe
          width="100%"
          height="400"
          src="https://www.youtube.com/embed/S3Yl5HYjmnc?si=fMYBhbxInS8QLw5e"
                    title="Dúvidas sobre Chat gpt 4"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Paper>
      
     
    </Box>
  );
};

export default LastDoubtsSection;
