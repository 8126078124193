import React from 'react';
import { Box, Typography, Link } from '@mui/material';

const PoliticaDePrivacidade = () => {
  return (
    <Box
      sx={{
        maxWidth: '800px',
        margin: 'auto',
        padding: '40px 20px',
        backgroundColor: '#000', // Fundo preto
        color: 'white',
        borderRadius: '8px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Typography variant="h4" gutterBottom>
        Política de Privacidade
      </Typography>

      <Typography variant="body1" gutterBottom>
        Esta Política de Privacidade descreve como coletamos, usamos e protegemos suas informações pessoais ao utilizar o Bootcamp Python com Projetos Reais.
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Coleta de Informações
      </Typography>
      <Typography variant="body1" gutterBottom>
        Coletamos informações pessoais, como nome, e-mail e informações de pagamento, quando você se inscreve em nosso Bootcamp.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. Uso das Informações
      </Typography>
      <Typography variant="body1" gutterBottom>
        Utilizamos suas informações para processar sua inscrição, fornecer suporte e enviar atualizações sobre o curso.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Compartilhamento de Informações
      </Typography>
      <Typography variant="body1" gutterBottom>
        Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para processar pagamentos ou cumprir requisitos legais.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Segurança
      </Typography>
      <Typography variant="body1" gutterBottom>
        Tomamos medidas adequadas para proteger suas informações pessoais contra acesso não autorizado, alteração ou destruição.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Seus Direitos
      </Typography>
      <Typography variant="body1" gutterBottom>
        Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para isso, entre em contato conosco.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Alterações a Esta Política
      </Typography>
      <Typography variant="body1" gutterBottom>
        Reservamo-nos o direito de atualizar esta Política de Privacidade. Notificaremos você sobre quaisquer alterações significativas.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Contato
      </Typography>
      <Typography variant="body1" gutterBottom>
        Para perguntas sobre esta Política de Privacidade, entre em contato conosco através do e-mail: loscodebr@gmail.com
      </Typography>

      <Typography variant="body1" gutterBottom>
        Última atualização: {new Date().toLocaleDateString()}
      </Typography>
    </Box>
  );
};

export default PoliticaDePrivacidade;
