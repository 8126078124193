import React from 'react';
import { Typography, Divider, Box, Card, Grid } from '@mui/material';
import { styled } from '@mui/system';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import CodeIcon from '@mui/icons-material/Code';
import SchoolIcon from '@mui/icons-material/School'; // Ícone para estudos

// Estilo para o Divider com gradiente laranja e preto
const GradientDivider = styled(Divider)(({ theme }) => ({
  height: '2px',
  background: 'linear-gradient(to right, #FF6A00, black)',
  border: 'none',
}));

// Estilo para o Card com fundo preto e bordas arredondadas
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: '#1a1a1a',
  color: '#fff',
  margin: theme.spacing(2),
  padding: theme.spacing(3),
  borderRadius: '15px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.7)',
  textAlign: 'center',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

// Componente para renderizar o conteúdo dos cards
const CardContentStyled = ({ title, description, icon }) => (
  <StyledCard>
    {icon}
    <Typography variant="h6" gutterBottom sx={{ mt: 1 }}>{title}</Typography>
    <Typography variant="body2">{description}</Typography>
  </StyledCard>
);

const ReasonSection = () => {
  return (
    <Box sx={{ maxWidth: '1200px', margin: 'auto', textAlign: 'center', padding: '40px 20px' }}>
      {/* Título da seção */}
      <Typography variant="h4" align="center" sx={{ mb: 2, fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 'bold' }}>
        EXPERIMENTE O PODER DO CHAT GPT-4 AGORA MESMO
      </Typography>
      
      <Typography variant="h5" align="center" sx={{ mb: 2, fontFamily: 'Roboto, sans-serif', color: '#fff', fontWeight: 'italic' }}>
        Acelere sua produtividade, inove suas conversas e crie conteúdo de forma revolucionária com a inteligência do futuro!
      </Typography>
      <GradientDivider />

      {/* Grid com os cards */}
      <Grid container spacing={4} sx={{ mt: 4 }}>
        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Respostas Instantâneas"
            description="Obtenha respostas precisas e rápidas para todas as suas perguntas, a qualquer hora."
            icon={<AccessAlarmIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Criação de Conteúdo"
            description="Automatize a criação de textos criativos, artigos e códigos com inteligência avançada."
            icon={<CodeIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CardContentStyled
            title="Apoio nos Estudos"
            description="Receba ajuda personalizada em diversos assuntos e aprenda de maneira mais eficiente."
            icon={<SchoolIcon sx={{ fontSize: 40, color: '#FF6A00' }} />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReasonSection;
