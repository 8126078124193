import React from 'react';
import { Box, Typography, Button, Divider } from '@mui/material';
import { motion } from 'framer-motion';
import chatgptImage from '../img/chatgpt.jpg'; // Ajuste o caminho conforme necessário

const OfertaChatGPT = () => {
  return (
    <Box
      sx={{
        background: 'linear-gradient(135deg, #000 30%, #1a1a1a 90%)',
        color: '#fff',
        padding: '60px 40px',
        borderRadius: '16px',
        textAlign: 'center',
        boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)',
      }}
    >
      {/* Título da Oferta */}
      <motion.div
        initial={{ y: -50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, ease: 'easeOut' }}
      >
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            color: '#FF6A00',
            fontWeight: 'bold',
            letterSpacing: '2px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          OFERTA EXCLUSIVA: ACESSO CHAT GPT-4!
        </Typography>
      </motion.div>

      {/* Imagem do Chat GPT-4 */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3, ease: 'easeOut' }}
      >
        <img
          src={chatgptImage}
          alt="Chat GPT-4"
          style={{
            width: '100%',
            maxWidth: '500px',
            borderRadius: '10px',
            margin: '20px 0',
          }}
        />
      </motion.div>

      {/* Preço e Comparação */}
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: '#FF6A00',
            fontSize: '28px',
            fontWeight: '500',
            marginTop: '20px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          <strong>Oferta Limitada:</strong> De <span style={{ textDecoration: 'line-through', color: '#fff' }}>R$ 105,90/Mês</span> por <span style={{ color: '#FF6A00', fontWeight: 'bold' }}>R$ 29,90 </span>!
          <br />
          Obtenha acesso completo ao Chat GPT-4 e revolucione a forma como trabalha e aprende!
        </Typography>
      </motion.div>

      {/* Divisor */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5, ease: 'easeOut' }}
      >
        <Divider
          sx={{
            backgroundColor: '#FF6A00',
            height: '3px',
            margin: '30px 0',
            width: '80%',
            mx: 'auto',
          }}
        />
      </motion.div>

      {/* Descrição da Oferta */}
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.4, ease: 'easeOut' }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: '20px',
            margin: '20px 0',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Aumente sua produtividade com respostas instantâneas, crie conteúdos automaticamente e receba suporte personalizado em seus estudos com o Chat GPT-4!
        </Typography>
      </motion.div>

      <motion.div
        initial={{ x: 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.5, ease: 'easeOut' }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            fontSize: '20px',
            marginBottom: '30px',
            fontFamily: 'Poppins, sans-serif',
          }}
        >
          Aproveite essa oportunidade exclusiva para transformar suas conversas e aumentar sua eficiência!
        </Typography>
      </motion.div>

      {/* Botão de Chamada para Ação */}
      <motion.div
        whileHover={{ scale: 1.05 }}
        transition={{ type: 'spring', stiffness: 300 }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#FF6A00',
            color: '#fff',
            fontSize: '18px',
            fontWeight: 'bold',
            padding: '15px 30px',
            borderRadius: '50px',
            transition: 'all 0.3s ease',
            '&:hover': {
              backgroundColor: '#e65b00',
            },
          }}
          href="https://pay.kiwify.com.br/8NjkSoU" // Adicione o link correto
        >
          Comece a Usar o Chat GPT-4 Agora!
        </Button>
      </motion.div>

      {/* Garantia */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.7, ease: 'easeOut' }}
      >
        <Typography
          variant="body2"
          sx={{
            marginTop: '30px',
            fontSize: '16px',
            fontFamily: 'Poppins, sans-serif',
            opacity: 0.8,
          }}
        >
          Teste por 7 dias! Se não ficar satisfeito, devolvemos seu dinheiro.
          Sem riscos, apenas resultados!
        </Typography>
      </motion.div>
    </Box>
  );
};

export default OfertaChatGPT;
